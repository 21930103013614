<template>
  <v-container class="wp-page page-buongusto">
    <h1 v-if="title" v-html="title"></h1>
    <v-runtime-template :template="template" @click="clicked" />
  </v-container>
</template>
<script>
import NinjaForm from "~/components/ninjaForms/NinjaForm.vue";
import CitiesList from "@/views/wordpress/CitiesList.vue";
import GeocodeMap from "@/views/wordpress/GeocodeMap.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import WordPressSlider from "@/components/wordpress/WordPressSlider.vue";
import WarehouseCard from "@/components/wordpress/WarehouseCard.vue";
import WarehouseCards from "@/components/wordpress/WarehouseCards.vue";
import ShopAtWorkSubscription from "@/components/wordpress/ShopAtWorkSubscription.vue";
import WarehouseMap from "@/components/wordpress/WarehouseMap.vue";

import {
  VImg,
  VBtn,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelContent,
  VExpansionPanelHeader,
  VAlert,
  VTooltip,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VDivider,
  VList,
  VListItem,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VDialog
} from "vuetify/lib";

import VRuntimeTemplate from "v-runtime-template";

import get from "lodash/get";
import CMSService from "~/service/cmService";

import clickHandler from "~/mixins/clickHandler";
// import page from "~/mixins/page";

export default {
  name: "PageBuongusto",
  mixins: [clickHandler],
  data() {
    return {
      page: null,
      template: null
    };
  },
  /* eslint-disable vue/no-unused-components */
  components: {
    VRuntimeTemplate,
    VImg,
    VBtn,
    VExpansionPanel,
    VExpansionPanels,
    VExpansionPanelContent,
    VExpansionPanelHeader,
    VAlert,
    VTooltip,
    VCard,
    VCardActions,
    VCardSubtitle,
    VCardText,
    VCardTitle,
    VDivider,
    VList,
    VListItem,
    VListItemContent,
    VListItemSubtitle,
    VListItemTitle,
    VDialog,
    NinjaForm,
    ProductListSlider,
    CitiesList,
    GeocodeMap,
    WordPressSlider,
    WarehouseCard,
    WarehouseCards,
    ShopAtWorkSubscription,
    WarehouseMap
  },
  computed: {
    title() {
      return this.page?.hide_title == "1"
        ? null
        : get(this.page, "title.rendered");
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      this.page = await CMSService.getCustomPostBySlug(
        "ristoranti_buongusto",
        this.$route.params.slug
      );
      this.page = this.page[0];
      if (this.page && this.page.content) {
        this.template = `<div>${this.page.content.rendered}</div>`;
      }
    });
  }
};
</script>
